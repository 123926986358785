const billetExpData = [
  { partner: "asp", expFirstInstallment: 0, expInstallments: 0 },
  { partner: "ativossa", expFirstInstallment: 10, expInstallments: 35 },
  { partner: "bancodobrasil", expFirstInstallment: 30, expInstallments: 30 },
  { partner: "bradesco", expFirstInstallment: 15, expInstallments: 15 },
  { partner: "btg", expFirstInstallment: 0, expInstallments: 20 },
  { partner: "carrefour", expFirstInstallment: 5, expInstallments: 25 },
  { partner: "cielo", expFirstInstallment: 0, expInstallments: 0 },
  { partner: "claro", expFirstInstallment: 8, expInstallments: 8 },
  { partner: "crediativos", expFirstInstallment: 7, expInstallments: 7 },
  { partner: "credsystem", expFirstInstallment: 10, expInstallments: 0 }, // expInstallments: "Gerido pelo parceiro"
  { partner: "credz", expFirstInstallment: 0, expInstallments: 0 },
  { partner: "dacasa", expFirstInstallment: 7, expInstallments: 30 },
  { partner: "havan", expFirstInstallment: 5, expInstallments: 7 },
  { partner: "hoepers", expFirstInstallment: 5, expInstallments: 80 },
  { partner: "ipanema", expFirstInstallment: 8, expInstallments: 8 },
  { partner: "itapeva", expFirstInstallment: 5, expInstallments: 5 },
  { partner: "itau", expFirstInstallment: 3, expInstallments: 10 },
  { partner: "itau-rdhi", expFirstInstallment: 3, expInstallments: 10 },
  { partner: "kroton", expFirstInstallment: 0, expInstallments: 5 },
  { partner: "liftcred", expFirstInstallment: 4, expInstallments: 10 },
  { partner: "colombo", expFirstInstallment: 5, expInstallments: 5 },
  { partner: "lojasrenner", expFirstInstallment: 10, expInstallments: 10 },
  { partner: "net", expFirstInstallment: 3, expInstallments: 10 },
  { partner: "nubank", expFirstInstallment: 0, expInstallments: 0 }, // expInstallments: "Colchão gerido pela Nubank"
  { partner: "recovery", expFirstInstallment: 20, expInstallments: 30 },
  { partner: "riachuelo", expFirstInstallment: 0, expInstallments: 0 }, // expInstallments: "Tratado pelo parceiro"
  { partner: "sky", expFirstInstallment: 3, expInstallments: 0 },
  { partner: "tricard", expFirstInstallment: 15, expInstallments: 60 },
  { partner: "trigg", expFirstInstallment: 10, expInstallments: 10 },
  { partner: "uzecartoes", expFirstInstallment: 8, expInstallments: 8 },
  { partner: "via", expFirstInstallment: 7, expInstallments: 30 },
  { partner: "vivo", expFirstInstallment: 0, expInstallments: 0 } // expInstallments: "Não temos colchão"
];

/**
 * Checks if a billet has expired based on the current installment and partner identifier.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.currentInstallment - The current installment object containing due date and installment number.
 * @param {string} params.partnerIdentifier - The identifier of the partner.
 * @throws {Error} Throws an error if currentInstallment is invalid or if the installment number is invalid.
 * @returns {boolean} Returns true if the billet has expired, false otherwise.
 */
const checkBilletExpired = ({ currentInstallment, partnerIdentifier }: any) => {
  // Check if currentInstallment is a valid object
  if (!currentInstallment || typeof currentInstallment !== 'object') {
    throw new Error("Invalid currentInstallment object."); // Returns false for invalid object
  }

  // Destructuring with default values
  const { dueDate = new Date(), installmentNumber = 0 } = currentInstallment;

  // Check if installmentNumber is a valid number
  if (typeof installmentNumber !== 'number' || installmentNumber < 1) {
    throw new Error("Invalid installment number."); // Returns false for invalid installment number
  }

  const isFirstInstallment = installmentNumber === 1;
  const partnerData = billetExpData.find(p => p.partner === partnerIdentifier);

  if (!partnerData) {
    return false; // Returns false if partner is not found
  }

  // Check if the expiration period is a number
  let expirationPeriod = isFirstInstallment ? partnerData.expFirstInstallment : partnerData.expInstallments;

  // Convert to number if necessary
  expirationPeriod = typeof expirationPeriod === 'string' ? parseInt(expirationPeriod) : expirationPeriod;

  const now = new Date();
  const due = new Date(dueDate);

  // Calculate the expiration date
  const expirationDate = new Date(due);
  expirationDate.setDate(due.getDate() + expirationPeriod);
  expirationDate.setHours(23, 59, 59, 999);

  // Check if the billet has expired
  return now > expirationDate;
}

export default checkBilletExpired;
