const checkInstallmentDueDateDMinus35 = (currentInstallment: any) => {
  const { dueDate = new Date() } = currentInstallment;
  const expirationPeriod = 35;

  const now = new Date();
  const due = new Date(dueDate);

  const expirationDate = new Date(due);
  expirationDate.setDate(now.getDate() - expirationPeriod);

  const diffTime = due.getTime() - expirationDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return diffDays >= 35;
};

export default checkInstallmentDueDateDMinus35
