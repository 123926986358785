import { useNavigate, useLocation } from "react-router-dom";

import { useRedline } from "@libs/redline";

import { pageNames } from "@common/routes/pageNames";
import constants from '@common/constants';

type OnErrorProps = {
  error: any;
  useCustomErrorMessage?: "implicit" | "engage";
}

const useError = () =>  {
	const navigate = useNavigate();
	const location = useLocation();

	const { search } = location;
	
	const { track: trackRedline, getCorrelationId } = useRedline();
	
	const handleError = ({ error, useCustomErrorMessage }: OnErrorProps) => {
		const customErrorMessage = {
			engage: "Engage access error",
			implicit: "Implicit access error - no account"
		}

		const errorMessage = useCustomErrorMessage ? customErrorMessage[useCustomErrorMessage] : error?.message ? error?.message : "Unknown message";
	
		trackRedline.userAuthentication.signInErrored({
			correlationId: getCorrelationId(constants.redline.RL_TRACK_CID),
			errorDetails: error?.error_code || "Unknown details",
			errorMessage: errorMessage,
			errorType: error?.error_slug || "Unknown type",
		});

		if (error.fields?.length > 0) {
			return navigate(pageNames.signup.path);
		}

		if (error.error_slug === 'mfa_required') {
			return navigate(`${pageNames.signin.path}${search}`, { state: { isMyDebtsEngageSignin: true } });
		}

		if(error.error_slug === 'customer_not_found') {
			return navigate(pageNames.signup.path);
		}

		if (error.error_slug === 'unauthorized') {
			return navigate(pageNames.myDebts.path);
    }

		navigate(pageNames.signin.path);
	}

	return {
		handleError
	}
}

export default useError;