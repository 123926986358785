/**
 * Represents an object that defines key-value pairs for error context.
 */
type ErrorContext = {
  [key: string]: string | number;
};

/**
 * Utility object for interacting with DataDog's DD_RUM error reporting.
 */
const acDatadog = {
  /**
   * Adds a custom error to DataDog's DD_RUM.
   * @param {string} errorMessage The error message to associate with the error.
   * @param {ErrorContext} [errorContext] Additional context data to associate with the error.
   */
  addCustomErrorDDRUM: (errorMessage: string, errorContext?: ErrorContext) => {
    const err = new Error(errorMessage);

    window?.DD_RUM?.onReady(() => {
      window?.DD_RUM?.addError(err, errorContext);
    });
  },

  /**
   * Adds or updates a property in the global error context of DD_RUM.
   * @param {string} propertyName The name of the property to add or update in the global context.
   * @param {ErrorContext} [errorContext] The context data associated with the property.
   */
  addErrorContextProperty: (propertyName: string, errorContext?: ErrorContext) => {
    window?.DD_RUM?.onReady(() => {
      window?.DD_RUM?.setGlobalContextProperty(propertyName, errorContext);
    });
  },

  /**
    * Retrieves the current global error context from DD_RUM.
    * @returns {ErrorContext | undefined} The current global error context, or undefined if not available.
    */
  getErrorContext: (): ErrorContext | undefined => {
    let context: ErrorContext | undefined;

    window?.DD_RUM?.onReady(() => {
      context = window?.DD_RUM?.getGlobalContext();
    });

    return context;
  },

  /**
   * Removes a property from the global error context of DD_RUM.
   * @param {string} propertyName The name of the property to remove from the global context.
   */
  removeErrorContextProperty: (propertyName: string) => {
    window?.DD_RUM?.onReady(() => {
      window?.DD_RUM?.removeGlobalContextProperty(propertyName);
    });
  },

  /**
   * Clears the entire global error context of DD_RUM.
   */
  clearErrorContext: () => {
    window?.DD_RUM?.onReady(() => {
      window?.DD_RUM?.clearGlobalContext();
    });
  },
};

export default acDatadog;
