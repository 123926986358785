import { ReactComponent as ACLogo } from '@libs/atlas/assets/images/ac-logo-white-border.svg';
import './styles.scss';

export default function PageLoader() {

  return (
    <div className="page-loader">
      <ACLogo />
    </div>
  );
}

