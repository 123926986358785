// hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Utils
import { getMarketplaceAuthToken } from './getMarketplaceAuthToken';

export const sendPushDataToMarketplace = async (data: object) => {
  try {
    const configurationJaiminho = usePushWebSettingsStore.getState();
    if (configurationJaiminho.settings.marketplaceApi === '') {
      return console.error('Push web: Api not defined.');
    }

    const authToken = getMarketplaceAuthToken();
    await fetch(`${configurationJaiminho.settings.marketplaceApi}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.error('Push Web: Send push data', error);
  }
};
