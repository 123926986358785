import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useRedline } from '@libs/redline';
import {
  pageNames,
  pagePaths,
  privatePagePaths,
  publicPagePaths,
} from '@common/routes/pageNames';
import PublicRoutes from '@common/routes/PublicRoutes';
import PrivateRoutes from '@common/routes/PrivateRoutes';
import PageLoader from '@common/components/PageLoader';
import useTitle from '@common/hooks/useTitle';
import useDatadogTrackView from '@libs/utils/hooks/useDatadogTrackView';
import { Router as useAdmissionRouter } from '@admission/routes/Router';
import { Router as useDuesRouter } from '@dues/routes/Router';
import { Router as useTallyRouter } from '@tally/routes/Router';
import { Router as useHubRouter } from '@hub/routes/Router';

const NotFound = lazy(() => import('@common/pages/NotFound'));
const Account = lazy(() => import('@admission/pages/Account'));

export function Router() {
  const [alreadyRanFirstPageload, setAlreadyRanFirstPageload] = useState(false);
  const location = useLocation();
  const { track } = useRedline();
  const { titlePage, setCurrentPath } = useTitle();

  const pagesAdmission = useAdmissionRouter();
  const pagesDues = useDuesRouter();
  const pagesTally = useTallyRouter();
  const pagesHub = useHubRouter();
  useDatadogTrackView({ pagePaths, privatePagePaths, publicPagePaths });

  useEffect(() => {
    setCurrentPath(location.pathname);

    if (!alreadyRanFirstPageload) {
      setAlreadyRanFirstPageload(true);
    } else {
      setTimeout(() => {
        track.auto.pageViewed();
      }, 800);
    }
  }, [location]);

  useEffect(() => {
    document.title = `${titlePage} Acordo Certo`;
  }, [titlePage]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route element={<PublicRoutes />}>{pagesAdmission}</Route>
        <Route element={<PrivateRoutes />}>
          {pagesDues}
          {pagesTally}
          {pagesHub}
          <Route path={pageNames.account.path} element={<Account />} />
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
