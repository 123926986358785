import { AgreementResponseProps } from "@dues/apis/agreements/types";

/**
 * Updates the situation of agreements based on the situation of their installments.
 * - If all installments are PAYMENT_COMPLETED, the agreement situation is set to PAID.
 * - If all installments are CANCELED, the agreement situation is set to CANCELLED.
 * - If the agreement situation is BROKEN, it is also set to CANCELLED.
 *
 * @param {AgreementResponseProps[]} agreementsList - The list of agreements to be updated.
 * @returns {AgreementResponseProps[]} The updated list of agreements with modified situations.
 */
const updateAgreementsSituations = (agreementsList: AgreementResponseProps[]) => {
  return agreementsList.map(agreement => {
    const { installments = [], situation } = agreement;

    // Check the situations of the installments
    const allInstallmentsCompleted = installments?.every(
      installment => installment?.situation === 'PAYMENT_COMPLETED'
    );

    const allInstallmentsCanceled = installments?.every(
      installment => installment?.situation === 'CANCELED'
    );

    // Determine the new situation based on the conditions
    let newSituation = situation;

    if (allInstallmentsCompleted) {
      newSituation = 'PAID';
    } else if (allInstallmentsCanceled || situation === 'BROKEN') {
      newSituation = 'CANCELLED';
    }

    return {
      ...agreement,
      situation: newSituation,
    };
  });
};

export default updateAgreementsSituations;
