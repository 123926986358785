import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

const Loans = lazy(() => import('@hub/pages/Loans'));
const Details = lazy(() => import('@hub/pages/Details'));

export function Router() {
  return (
    <>
      <Route path={pageNames.loans.path} element={<Loans />} />
      <Route path={pageNames.details.path} element={<Details />} />;
    </>
  );
}
