import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSH } from '@libs/sortinghat';
import { delay } from '@libs/utils/helpers/delay';
import { useUserStore } from '@common/store/useUserStore';
import useTrackOffer from '@common/hooks/useTrackOffer';

type UseSortingHat = {
  immediate?: boolean;
  decideDelayInSeconds?: number;
};

type SHRequestParams = {
  payload?: {
    documento?: string;
    [key: string]: any;
  };
  forceCall?: boolean;
};

export const useSortingHatLib = ({
  immediate,
  decideDelayInSeconds
}: UseSortingHat = {}) => {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const {
    callSHTrack,
    callSHDecision,
    decisionsList,
    trackDone,
    decideDone,
    SHLoading: shLoading,
    resetContext: resetSHContext
  } = useSH();

  const {
    trackDecisionEnriched,
    trackDecisionRequested,
    trackDecisionOutcomeReceived,
  } = useTrackOffer();

  const [shError, setShError] = useState(false);

  const disabledSHPages = [
    '/confirmacao',
    '/carrinho',
    '/acordo-fechado',
  ]

  const chooseDisabledSHPages = (pages: string[]): boolean => {
    return pages.some((pageName) => location.pathname.includes(pageName));
  };

  const hasADisabledSHPage = chooseDisabledSHPages(disabledSHPages);

  useEffect(() => {
    if (immediate && !hasADisabledSHPage) {
      shTrack();
      if(trackDone) {
        shDecision()
      };
    }
  }, [user, trackDone, immediate, hasADisabledSHPage]);

  const shTrack = useCallback(
    (customPayload?: SHRequestParams) => {
      try {
        const safePayload = {
          ...user,
          ...customPayload?.payload,
        };
          callSHTrack({
            user: safePayload,
            decisionEnrichedEvent: trackDecisionEnriched,
            forceCall: customPayload?.forceCall || false,
          });

      } catch (error) {
        console.error('[SH][TRACK]: ', error);
      }
    },
    [user, hasADisabledSHPage]
  );

  const shDecision = useCallback(
    async (customPayload?: SHRequestParams) => {
      try {
        if (decideDelayInSeconds) {
          // this logic is necessary because decide default delay is 1 second
          const milliseconds = (decideDelayInSeconds - 1) * 1000;
          if (milliseconds > 0) {
            await delay(milliseconds);
          }
        }

        const safePayload = {
          ...user,
          ...customPayload?.payload,
        };

        const result = await callSHDecision({
          user: safePayload,
          decisionRequestEvent: trackDecisionRequested,
          decisionOutcomeReceivedEvent: trackDecisionOutcomeReceived,
          forceCall: customPayload?.forceCall || false,
        });

        setShError(false);
        return result;
      } catch (error) {
        setShError(true);
        console.error('[SH][DECIDE] ', error);
      }
    },
    [user, trackDone, decideDelayInSeconds]
  );


  const retryDecide = useCallback(async () => {
    if (!trackDone) await delay(5000);
    if (decisionsList.length === 0) {
      if (trackDone) {
        await delay(3000);
        await shDecision({ forceCall: true });
      } else if (!hasADisabledSHPage){
        shTrack({ forceCall: true });
      }
    }
  }, [decisionsList, trackDone, hasADisabledSHPage]);

  return {
    shTrack,
    shDecision,
    decisionsList,
    decideDone,
    shError,
    shLoading,
    trackDone,
    retryDecide,
    resetSHContext
  };
};
