import constants from "@common/constants";
import safestorage from "@libs/utils/helpers/getSafeStorage";

import useApiOnboarding from "@admission/apis/onboarding/useApiOnboarding";
import { useLocation, useNavigate } from "react-router-dom";
import { pageNames } from "@common/routes/pageNames";

import { useUserStore } from "@common/store/useUserStore";

import useIsAdmin from "@admission/hooks/useIsAdmin";

import useSuccessSignIn from "../common/useSuccessSignIn";
import useImplicitMFA from "../common/useImplicitMFA";
import useError from "../common/useError";

import { delay } from "@libs/utils/helpers/delay";
import useUserAuthentication from "@admission/hooks/useUserAuthentication";

const useEngageSignIn = () => {
	const { addFindUser } = useUserStore();
	const { updateUserAdmin } = useIsAdmin();
	const { handleError } = useError();

	const { 
		addCookieRLTracksAuth, 
	} = useUserAuthentication();

	const { handleSuccessSignIn } = useSuccessSignIn();
	const { handleImplicitMFA } = useImplicitMFA();

	const location = useLocation();
	const navigate = useNavigate();
	
	const { search } = location;
	const searchParams = new URLSearchParams(search);

	const engageSigninData = searchParams.get("e");
	
	const {
		apiOnboardingSigninEngage
	} = useApiOnboarding();

	const isMaskedContact = (contact: string) => {
    return contact.includes("*")
  }

	async function engageSignIn() {
    safestorage.setItem(
      constants.auth.AUTH_USER_TOKEN,
      engageSigninData
    ).then(_ => {
      apiOnboardingSigninEngage
      .send({ token: engageSigninData })
      .then((response: any) => {
				addCookieRLTracksAuth("email-sign-in");

				if (!response.data?.auth?.access_token) {
          const { user } = response.data;

          if (isMaskedContact(user?.email) && isMaskedContact(user?.celular)) {
            handleImplicitMFA(response.data.user);

          }else {
						addFindUser({ celular: response.data?.user.celular, email: response.data?.user.email, documento: response.data?.user.documento });
					
						return navigate(`${pageNames.signin.path}${search}`, { state: { isMyDebtsEngageSignin: true } });
					}

        } else {
					handleSuccessSignIn(response);
          updateUserAdmin(response);
					
					//onSuccessRedirect
					delay(500).then(() => {
						const redirect = pageNames.myDebts.path;

						navigate(redirect, { replace: true });
					});
					
        }
      })
      .catch((err) => {
        handleError({ error: err, useCustomErrorMessage: "engage" });
      });
    });
  }

	return {
		engageSignIn
	}
}

export default useEngageSignIn;