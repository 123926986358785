import {
  ARC4U_IDENTIFIER,
  MAGALU_PARTNER_NAME,
  ARC4U_PARTNER_NAME,
} from '@common/constants/hard-coded-partners';
import useLuizacredLogo from '@common/hooks/useLuizacredLogo';
import {
  IconMoney,
  IconCheckCircle,
  IconXOctagon,
} from '@consumidor-positivo/aurora';
import {
  AgreementResponseProps,
  AgreementPropertyProps,
} from '@dues/apis/agreements/types';
import { ReportProps } from '@dues/pages/MyAgreements/types';
import { dateToLocale } from '@libs/utils/helpers/dateToLocale';

const getIsArcPartner = (agreement: AgreementResponseProps): boolean => {
  const partnerId = agreement?.partnerSystem?.identificador;
  if (partnerId !== 'btg') return false;
  const safeProperties = agreement?.properties || [];
  const isArcPartner = safeProperties?.some((prop: AgreementPropertyProps) => {
    return prop?.key === 'ARC_DEBT';
  });
  return isArcPartner;
};

const mountLogo = (agreement: AgreementResponseProps) => {
  const { showLogoMagalu, currentLogoIdentifier } = useLuizacredLogo(
    agreement?.partner?.identificador,
    agreement?.partnerSystem?.identificador,
    agreement?.debt?.origem,
    agreement?.debt?.produto
  );

  const currentLogoMyAgreements = showLogoMagalu
    ? currentLogoIdentifier
    : agreement?.partnerSystem?.identificador?.toLowerCase();

  return getIsArcPartner(agreement)
    ? ARC4U_IDENTIFIER
    : currentLogoMyAgreements;
};

const handlePartnerName = (agreement: AgreementResponseProps) => {
  const partnerId = agreement?.partnerSystem?.identificador;

  if (partnerId === 'sophus') {
    const logoProp = agreement?.properties?.find(
      (prop: AgreementPropertyProps) => prop?.key === 'DEBT_LOGO'
    );

    if (logoProp)
      return (
        logoProp?.stringValue?.charAt(0)?.toUpperCase() +
        logoProp?.stringValue?.slice(1)
      );
  }

  const { showLogoMagalu } = useLuizacredLogo(
    agreement?.partner?.identificador,
    agreement?.partnerSystem?.identificador,
    agreement?.debt?.origem,
    agreement?.debt?.produto
  );

  if (showLogoMagalu) {
    return MAGALU_PARTNER_NAME;
  }

  if (getIsArcPartner(agreement)) {
    return ARC4U_PARTNER_NAME;
  }

  return agreement?.partnerSystem?.nome;
};

const mountAgreementCardReport = (
  agreement: AgreementResponseProps
): ReportProps => {
  const reportOptions: Record<string, ReportProps> = {
    ACTIVE: {
      status: 'Acordo em andamento',
      warningType: 'warning',
      icon: <IconMoney />,
    },
    // BROKEN: { >>>> I left the BROKEN status commented out to remind you that there is such a situation in agreements, this situation is converted into CANCELLED in agreementsContext
    //   status: 'Acordo cancelado',
    //   warningType: 'error',
    //   icon: <IconXOctagon />,
    // },
    CANCELLED: {
      status: 'Acordo cancelado',
      warningType: 'error',
      icon: <IconXOctagon />,
    },
    PAID: {
      status: 'Pagamento confirmado',
      warningType: 'success',
      icon: <IconCheckCircle />,
    },
  };

  return reportOptions[agreement?.situation ?? 'ACTIVE'];
};

const mountAgreement = (agreement: AgreementResponseProps) => {
  return {
    id: agreement?.id,
    contract: agreement?.contract,
    partnerLogo: mountLogo(agreement),
    partnerLogoAlt: `Acordo Certo ${handlePartnerName(agreement)}`,
    category: agreement?.debt?.produto,
    partnerSlug: handlePartnerName(agreement),
    reportStatus: mountAgreementCardReport(agreement)?.status,
    reportType: mountAgreementCardReport(agreement)?.warningType,
    reportIcon: mountAgreementCardReport(agreement)?.icon,
    agreementDatePTBR: dateToLocale(agreement?.agreementDate),

    // >>>
    agreementDate: agreement?.agreementDate,
    agreementNumber: agreement?.id,
    agreementValue: agreement?.agreementValue,
    allowsCancelation: agreement?.allowsCancelation,
    debt: agreement?.debt,
    installments: agreement?.installments,
    originalInstallments: agreement?.originalInstallments,
    partner: agreement?.partner,
    partnerSystem: agreement?.partnerSystem,
    paymentMethod: agreement?.paymentMethod,
    properties: agreement?.properties,
    providerAgreementValue: agreement?.providerAgreementValue,
    providerInstallments: agreement?.providerInstallments,
    situation: agreement?.situation, // is formatted in the CONTEXT
    status: agreement?.status,
    informations: agreement?.informations,
    documents: agreement?.documents,
  };
};

export default mountAgreement;
